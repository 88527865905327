import {Injectable} from "@angular/core";
import {API} from "@service/api";
import {Subject} from "rxjs";

// interface
import {ISettingsResponse} from "@service/api_interface.helper";

@Injectable({
  providedIn: "root"
})
export class SettingsService {
  settings: ISettingsResponse;

  public _changeSettings = new Subject();

  constructor(private api: API) {
    this.requestToGetInfo();
  }

  private async requestToGetInfo() {
    try {
      let res = await this.api.getSettings().toPromise();
      this.updateSettings(res);
    } catch (e) {
      console.log(e);
    }
  }

  public updateSettings(settings: ISettingsResponse) {
    this._changeSettings.next(settings);
  }
}