import {Component} from "@angular/core";

@Component({
  selector: "app-section-1",
  templateUrl: "./section1.component.html",
  styleUrls: ["./section1.component.scss"]
})
export class Section1Component {
  constructor() {

  }
}