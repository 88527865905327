import {Component, OnInit} from "@angular/core";

import {fade} from "@service/animation";
import {SliderService} from "@app/business-logic/slider.service";

@Component({
  selector: "app-section-6",
  templateUrl: "./section6.component.html",
  styleUrls: ["./section6.component.scss"],
  animations: fade,
  providers: [SliderService]
})
export class Section6Component implements OnInit {

  section6List = [
    {
      img: "/assets/images/feature1.png",
      class: "heart-rate",
      title: "feature1",
    },
    {
      img: "/assets/images/feature2.png",
      class: "cardio-stress",
      title: "feature2",
    },
    {
      img: "/assets/images/feature3.png",
      class: "vital-energy",
      title: "feature3",
    },
    {
      img: "/assets/images/feature4.png",
      class: "activity-steps",
      title: "feature4",
    }
  ];

  activeIndex = 0;
  state = "in";

  constructor(private slider: SliderService) {
    this.slider.initSlider({
      length: this.section6List.length,
      intervalAnimation: 500,
      intervalSlider: 3500
    });
  }

  ngOnInit(): void {
    this.slider._changeActiveIndex.subscribe((el: number) => {
      this.activeIndex = el;
    });
    this.slider._changeActiveStatus.subscribe((el: string) => {
      this.state = el;
    });
  }

  changeToSlider(_i: number) {
    this.slider.changeToSlider(_i);
  }
}
