import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {API} from "@service/api";

// layout
import {HeaderComponent} from "@app/layout/header/header.component";
import {FooterComponent} from "@app/layout/footer/footer.component";

// components
import {AppComponent} from "./app.component";
import {BaseComponent} from "@app/components/base/base.component";
import {RootRoutes} from "./app.routing";
import {AboutComponent} from "@app/module/about/about.component";
import {ContactComponent} from "@app/module/contact/contact.component";
import {HomeComponent} from "@app/module/home/home.component";
import {PostComponent} from "@app/module/post/post.component";
import {PostsComponent} from "@app/components/posts/posts.component";
import {TermOfUseComponent} from "@app/module/term_of_use/term_of_use.component";
import {PrivacyPolicyComponent} from "@app/module/privacy_policy/privacy_policy.component";

import {Section1Component} from "@app/components/section-1/section1.component";
import {Section2Component} from "@app/components/section-2/section2.component";
import {Section3Component} from "@app/components/section-3/section3.component";
import {Section4Component} from "@app/components/section-4/section4.component";
import {Section5Component} from "@app/components/section-5/section5.component";
import {Section6Component} from "@app/components/section-6/section6.component";
import {Section7Component} from "@app/components/section-7/section7.component";
import {Section8Component} from "@app/components/section-8/section8.component";
import {Section9Component} from "@app/components/section-9/section9.component";
import {Section10Component} from "@app/components/section-10/section10.component";
import {Section11Component} from "@app/components/section-11/section11.component";

import {ArrowComponent} from "@app/components/arrow/arrow.component";

// service
import {SliderService} from "@app/business-logic/slider.service";
import {SettingsService} from "@app/business-logic/settings.service";

export function HttpLoaderFactory (http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    // layout
    HeaderComponent,
    FooterComponent,
    // components
    AboutComponent,
    ContactComponent,
    BaseComponent,
    PostComponent,
    HomeComponent,
    PostsComponent,
    TermOfUseComponent,
    PrivacyPolicyComponent,
    Section1Component,
    Section2Component,
    Section3Component,
    Section4Component,
    Section5Component,
    Section6Component,
    Section7Component,
    Section8Component,
    Section9Component,
    Section10Component,
    Section11Component,
    ArrowComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot(RootRoutes),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    API,
    SliderService,
    SettingsService
  ],
  bootstrap: [AppComponent]

})
export class AppModule {
}
