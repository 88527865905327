import {Component} from "@angular/core";

@Component({
  selector: "app-section-9",
  templateUrl: "./section9.component.html",
  styleUrls: ["./section9.component.scss"]
})
export class Section9Component {
  constructor() {

  }
}