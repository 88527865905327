import {Component} from "@angular/core";

@Component({
  selector: "app-section-5",
  templateUrl: "./section5.component.html",
  styleUrls: ["./section5.component.scss"]
})
export class Section5Component {
  constructor() {

  }
}