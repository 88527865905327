import {Component} from "@angular/core";
import {Router}    from "@angular/router";
import {API}       from "@service/api";

@Component({
  selector: "app-term-of-use",
  templateUrl: "./term_of_use.component.html",
  styleUrls: ["./term_of_use.component.scss"]
})
export class TermOfUseComponent {
  text: string;

  constructor(private router: Router, private api: API) {
    this.requestPageInfo();
  }

  async requestPageInfo() {
    try {
      let res = await this.api.getPage(162).toPromise();
      this.text = res.content.rendered;
    } catch (err) {
      console.log(err);
    }
  }
}