import {Component} from "@angular/core";

@Component({
  selector: "app-section-11",
  templateUrl: "./section11.component.html",
  styleUrls: ["./section11.component.scss"]
})
export class Section11Component {
  constructor() {

  }
}