import {Routes}                 from "@angular/router";
import {HomeComponent}          from "@app/module/home/home.component";
import {AboutComponent}         from "@app/module/about/about.component";
import {ContactComponent}       from "@app/module/contact/contact.component";
import {PostComponent}          from "@app/module/post/post.component";
import {PostsComponent}         from "@app/components/posts/posts.component";
import {TermOfUseComponent}     from "@app/module/term_of_use/term_of_use.component";
import {PrivacyPolicyComponent} from "@app/module/privacy_policy/privacy_policy.component";

export const RootRoutes: Routes = [
  {
    path: "",
    component: HomeComponent,
  },
  {
    path: "about-us",
    component: AboutComponent,
  },
  {
    path: "contact-us",
    component: ContactComponent,
  },
  {
    path: "posts",
    component: PostsComponent,
  },
  {
    path: "term-of-use",
    component: TermOfUseComponent,
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
  },
  {
    path: "post/:slug",
    component: PostComponent,
  }
];
