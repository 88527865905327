import {Component} from "@angular/core";

@Component({
  selector: "app-section-4",
  templateUrl: "./section4.component.html",
  styleUrls: ["./section4.component.scss"]
})
export class Section4Component {
  section4List = ["/assets/images/partner1.png", "/assets/images/partner2.png", "/assets/images/partner3.png", "/assets/images/partner4.png", "/assets/images/partner5.png", "/assets/images/partner6.png"];

  constructor() {

  }
}
