import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

interface Payload {
  length: number;
  intervalAnimation: number;
  intervalSlider: number;
}

@Injectable({
  providedIn: "root",
})
export class SliderService {
  length: number;
  intervalSlider: number;
  intervalAnimation: number;

  interval: any;
  activeIndex: number;
  intervalOnClick: any;
  state: string;

  public _changeActiveIndex = new Subject();
  public _changeActiveStatus = new Subject();

  constructor() {

  }

  public initSlider(payload: Payload) {
    this.activeIndex = 0;
    this.length = payload.length;
    this.intervalSlider = payload.intervalSlider;
    this.intervalAnimation = payload.intervalAnimation;
    this.startIntervalSlider();
  }

  public changeToSlider(_i: number) {
    event.preventDefault();
    this.clearIntervalSlider();
    this.cleatIntervalOnClick();
    this.startIntervalOnClick(_i);
  }

  private startIntervalOnClick(_i: number) {
    this.changeActiveStatus("out");
    this.intervalOnClick = setTimeout(() => {
      this.changeActiveIndex(_i);
      this.changeActiveStatus("in");
      this.startIntervalSlider();
    }, this.intervalAnimation);
  }

  private cleatIntervalOnClick() {
    clearInterval(this.intervalOnClick);
  }

  private startIntervalSlider() {
    this.interval = setInterval(() => {
      this.changeActiveStatus("out");
      setTimeout(() => {
        let index = this.activeIndex + 1;
        index + 1 > this.length ? this.changeActiveIndex(0) : this.changeActiveIndex(index);
        this.changeActiveStatus("in");
      }, this.intervalAnimation);
    }, this.intervalSlider);
  }

  private clearIntervalSlider() {
    clearInterval(this.interval);
  }

  private changeActiveIndex(_i: number) {
    this.activeIndex = _i;
    this._changeActiveIndex.next(_i);
  }

  private changeActiveStatus(_status: string) {
    this.state = _status;
    this._changeActiveStatus.next(_status);
  }
}