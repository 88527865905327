import {Component, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  constructor (
    private translate: TranslateService
  ) {
    translate.addLangs(["en", "de", "nl"]);
    translate.setDefaultLang("en");
    let t = localStorage.getItem("lang");
    if (t) {
      if (t !== "en") {
        translate.use(t);
      }
    }
  }

  ngOnInit (): void {
  }
}
