import {HttpClient} from "@angular/common/http";

// interface
import {IPagesPayload, IPostsPayload, ISettingsResponse} from "@service/api_interface.helper";
import {Injectable}                                      from "@angular/core";

@Injectable()
export class API {
  private serverLink: string = "https://hellwp.cardiomo.com/";

  constructor(private http: HttpClient) {
  }

  public getPost(payload: IPostsPayload) {
    return this.http.get(this.serverLink + "wp-json/wp/v2/posts?_embed", this.createQueryParams(payload));
  }

  public getAllPosts() {
    return this.http.get(this.serverLink + "wp-json/wp/v2/posts?_embed");
  }

  public getAllPages(payload?: IPagesPayload) {
    return this.http.get(this.serverLink + "wp-json/wp/v2/pages", this.createQueryParams(payload));
  }

  public getPage(id: number) {
    return this.http.get<any>(this.serverLink + `wp-json/wp/v2/pages/${id}`);
  }

  public getSettings() {
    return this.http.get<ISettingsResponse>(this.serverLink + "wp-json/custom/v1/main_info");
  }

  private createQueryParams(...params: any[]) {
    const result: { [param: string]: string | string[] } = {};
    for (const param of params) {
      for (const prop in param) {
        if (param.hasOwnProperty(prop)) {
          result[prop] = param[prop].toString();
        }
      }
    }

    return {params: result};
  }

}