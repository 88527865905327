// if empty default "desc"
export enum EOrder {
  asc = "asc", desc = "desc"
}

// if empty default "date"
export enum EOrderBy {
  author = "author", date = "date", id = "id", include = "include", modified = "modified", parent = "parent", relevance = "relevance", slug = "slug", include_slugs = "include_slugs", title = "title", menu_order = "menu_order"
}

export interface IPostsPayload {
  page?: number;
  per_page?: number;
  search?: string;
  offset?: number;
  order?: EOrder;
  orderby?: EOrderBy;
  slug: string;

}

export interface IPagesPayload {
  page?: number;
  per_page?: number;
  search?: string;
  offset?: number;
  order?: EOrder;
  orderby?: EOrderBy;
  slug?: string;
}

export enum ISettingNavigationObject {
  page = "page", custom = "custom"
}

export interface ISettingNavigation {
  "ID": number;
  "post_author": string;
  "post_date": string;
  "post_date_gmt": string;
  "post_content": string;
  "post_title": string;
  "post_excerpt": string;
  "post_status": string;
  "comment_status": string;
  "ping_status": string;
  "post_password": string;
  "post_name": string;
  "to_ping": string;
  "pinged": string;
  "post_modified": string;
  "post_modified_gmt": string;
  "post_content_filtered": string;
  "post_parent": number;
  "guid": string;
  "menu_order": number;
  "post_type": string;
  "post_mime_type": string;
  "comment_count": string;
  "filter": string;
  "db_id": number;
  "menu_item_parent": string;
  "object_id": string;
  "object": ISettingNavigationObject;
  "type": string;
  "type_label": string;
  "url": string;
  "title": string;
  "target": string;
  "attr_title": string;
  "description": string;
}

export interface IAdaptedSettingNavigation {
  id: number;
  title: string;
  url: string;
  object: ISettingNavigationObject;
}

export interface ISettingResponseAdapted {
  logo: string;
  description: string;
  navigation: IAdaptedSettingNavigation[];
  social: [
    { instagram: string },
    { linkedin: string },
    { facebook: string }
  ];
  email: string;
  phone: string;
  private_statement: string;
  terms_and_conditions_of_use: string;
  return_policy: string;
}

export interface ISettingsResponse {
  logo: string;
  description: string;
  navigation: ISettingNavigation[];
  social: [
    { instagram: string },
    { linkedin: string },
    { facebook: string }
  ];
  email: string;
  phone: string;
  private_statement: string;
  terms_and_conditions_of_use: string;
  return_policy: string;
}
