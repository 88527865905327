import {Component} from "@angular/core";
import {Router} from "@angular/router";
import {API} from "@service/api";

@Component({
  selector: "app-post",
  templateUrl: "./post.component.html",
  styleUrls: ["./post.component.scss"]
})
export class PostComponent {
  post: any;

  constructor(private router: Router, private api: API) {
    this.getUserPost();
  }

  async getUserPost() {
    let data = {
      slug: this.router.url.split("/")[2]
    };
    try {
      this.post = await this.api.getPost(data).toPromise();
    } catch (e) {
      console.log(e);
    }
  }
}