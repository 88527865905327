import {Component, OnInit} from "@angular/core";

@Component({
  selector: "app-arrow",
  templateUrl: "./arrow.component.html",
  styleUrls: ["./arrow.component.scss"]
})
export class ArrowComponent implements OnInit {
  isRotated = false;
  elToScroll: number;

  constructor() {

  }

  ngOnInit() {
    this.scrollEventInit();
  }

  scrollEventInit() {
    let clientHeight = document.documentElement.clientHeight;
    this.setScrollElements("cardiomo-slider");

    document.addEventListener("scroll", (e) => {
      let top = window.scrollY + clientHeight - 30;
      this.setScrollElements("cardiomo-slider");

      if (top > this.elToScroll) {
        this.setScrollElements("menu");
        if (!this.isRotated) {
          this.isRotated = true;
        }
      } else {
        this.setScrollElements("cardiomo-slider");
        this.isRotated = false;
      }
    });
  }

  setScrollElements(_name: string) {
    let el = document.getElementById(_name);
    if (el) {
      this.elToScroll = el.offsetTop + 100;
    }
  }

  goToTop() {
    event.preventDefault();
    window.scrollTo({
      top: this.elToScroll,
      behavior: "smooth",
    });
  }
}