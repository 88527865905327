import {Component} from "@angular/core";

@Component({
  selector: "app-section-2",
  templateUrl: "./section2.component.html",
  styleUrls: ["./section2.component.scss"]
})
export class Section2Component {
  section2List = [
    {
      img: "/assets/images/heart.png",
      text: "actual"
    },
    {
      img: "/assets/images/continuous_monitoring.png",
      text: "contin"
    },
    {
      img: "/assets/images/ai.png",
      text: "algor"
    },
    {
      img: "/assets/images/notifications.png",
      text: "instant"
    }
  ];

  constructor() {

  }
}
