import {Component, OnInit} from "@angular/core";
import {API}               from "@service/api";

@Component({
  selector: "app-posts",
  templateUrl: "./posts.component.html",
  styleUrls: ["./posts.component.scss"]
})
export class PostsComponent implements OnInit {
  posts: any;
  loading = true;

  constructor(private api: API) {

  }

  ngOnInit(): void {
    this.getAllPosts();
  }

  async getAllPosts() {
    this.loading = true;

    try {
      this.posts = await this.api.getAllPosts().toPromise();
    } catch (e) {
      console.log(e);
    }

    this.loading = false;
  }

  getImg(_img) {
    if (_img && _img[0]) {
      return _img[0].source_url;
    } else {
      return "https://hellwp.cardiomo.com/wp-content/uploads/2019/10/1.png";
    }
  }
}