import {Component} from "@angular/core";

@Component({
  selector: "app-section-8",
  templateUrl: "./section8.component.html",
  styleUrls: ["./section8.component.scss"]
})
export class Section8Component {
  constructor() {

  }
}