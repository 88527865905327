import {Component} from "@angular/core";

@Component({
  selector: "app-section-10",
  templateUrl: "./section10.component.html",
  styleUrls: ["./section10.component.scss"]
})
export class Section10Component {
  constructor() {

  }
}