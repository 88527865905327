import {Component, OnInit} from "@angular/core";

import {SettingsService} from "@app/business-logic/settings.service";
import {
  IAdaptedSettingNavigation,
  ISettingNavigationObject,
  ISettingsResponse
} from "@service/api_interface.helper";
import {IFlags} from "@app/layout/footer/footer.component";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
  logo: string;
  navigationArray: IAdaptedSettingNavigation[];

  flags: IFlags[] = [
    {img: "/assets/images/flags/de.png", title: "de"},
    {img: "/assets/images/flags/en.png", title: "en"},
    {img: "/assets/images/flags/nl.png", title: "nl"}
  ];

  constructor (private settings: SettingsService) {

  }

  ngOnInit (): void {
    this.settings._changeSettings.subscribe((el: ISettingsResponse) => {
      if (el) {
        this.logo = el.logo;
        this.navigationFormat(el.navigation);
      }
    });
  }

  private navigationFormat (_el) {
    this.navigationArray = _el.map(elem => {
      return {
        id: elem.ID,
        title: elem.title,
        object: elem.object,
        url: elem.object === ISettingNavigationObject.page ? this.parseUrl(elem.url) : elem.url
      };
    });
  }

  changeLanguage (lang: string) {
    localStorage.setItem("lang", lang);
    window.location.reload();
  }

  private parseUrl (_url: string): string {
    let exec = _url.split("/")[3];

    if (!!exec) {
      return exec;
    } else {
      return "/";
    }
  }
}
