import {Component} from "@angular/core";
import {Router}    from "@angular/router";
import {API}       from "@service/api";

@Component({
  selector: "app-privacy-policy",
  templateUrl: "./privacy_policy.component.html",
  styleUrls: ["./privacy_policy.component.scss"]
})
export class PrivacyPolicyComponent {
  text: string;

  constructor(private router: Router, private api: API) {
    this.requestPageInfo();
  }

  async requestPageInfo() {
    try {
      let res = await this.api.getPage(3).toPromise();
      this.text = res.content.rendered;
    } catch (err) {
      console.log(err);
    }
  }
}