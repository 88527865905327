import {Component, OnInit} from "@angular/core";

import * as AOS from "aos";

@Component({
  selector: "app-about-us",
  templateUrl: "./about.component.html"
})
export class AboutComponent implements OnInit {
  constructor() {

  }

  ngOnInit(): void {
    AOS.init();
  }
}