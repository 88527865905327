import {Component, OnInit} from "@angular/core";

import * as AOS from "aos";
import {SettingsService} from "@app/business-logic/settings.service";
import {ISettingsResponse} from "@service/api_interface.helper";

// @TODO fix this page after change route many time
@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html"
})
export class ContactComponent implements OnInit{
  email: string;
  phone: string;
  tel: string;

  constructor(private settings: SettingsService) {
    this.settings._changeSettings.subscribe((el: ISettingsResponse) => {
      console.log(el);
      if (el) {
        this.email = el.email;
        this.phone = el.phone;
        this.tel = this.convertNumber(el.phone);
      }
    });
  }

  ngOnInit(): void {
    AOS.init();
  }

  private convertNumber(_number) {
    let rex = /[ ()]/gmi;
    return _number.replace(rex, "");
  }
}