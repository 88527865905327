import {Component, OnInit} from "@angular/core";

import {SettingsService} from "@app/business-logic/settings.service";
import {IAdaptedSettingNavigation, ISettingNavigationObject, ISettingsResponse} from "@service/api_interface.helper";


export interface IFlags {
  img: string;
  title: string;
}

// @TODO subscribe on wordpress side CF7
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
  showPrivacyStatement = false;
  showTermsAndConditions = false;
  showReturnPolicy = false;

  logo: string;
  navigationArray: IAdaptedSettingNavigation[];
  socialLink: any;
  privateStatement: string;
  termsAndConditionsOfUse: string;
  returnPolicy: string;
  email: string;
  phone: string;
  tel: string;

  flags: IFlags[] = [
    {img: "/assets/images/flags/de.png", title: "de"},
    {img: "/assets/images/flags/en.png", title: "en"},
    {img: "/assets/images/flags/nl.png", title: "nl"}
  ];

  constructor (private settings: SettingsService) {

  }

  ngOnInit (): void {
    this.settings._changeSettings.subscribe((el: ISettingsResponse) => {
      if (el) {
        this.logo = el.logo;
        this.socialLink = el.social;
        this.privateStatement = el.private_statement;
        this.termsAndConditionsOfUse = el.terms_and_conditions_of_use;
        this.returnPolicy = el.return_policy;
        this.email = el.email;
        this.phone = el.phone;
        this.tel = this.convertNumber(el.phone);

        this.navigationFormat(el.navigation);
      }
    });
  }

  private navigationFormat (_el) {
    this.navigationArray = _el.map(elem => {
      return {
        id: elem.ID,
        title: elem.title,
        object: elem.object,
        url: elem.object === ISettingNavigationObject.page ? this.parseUrl(elem.url) : elem.url
      };
    });
  }

  private parseUrl (_url: string): string {
    let myRe = /[^\/]+$/gmi;
    let exec = myRe.exec(_url);
    if (!!exec) {
      return exec[0];
    } else {
      return "/";
    }
  }

  private convertNumber (_number: string): string {
    if (_number) {
      let rex = /[ ()]/gmi;
      return _number.replace(rex, "");
    }
    return "empty";
  }

  actionPrivacyStatement (_status: boolean) {
    this.changeBodyClass(_status);
    this.showPrivacyStatement = _status;
  }

  actionTermsAndConditions (_status: boolean) {
    this.changeBodyClass(_status);
    this.showTermsAndConditions = _status;
  }

  actionReturnPolicy (_status: boolean) {
    this.changeBodyClass(_status);
    this.showReturnPolicy = _status;
  }

  changeBodyClass (_status: boolean) {
    if (_status) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }

  changeLanguage (lang: string) {
    localStorage.setItem("lang", lang);
    window.location.reload();
  }

  closeAllModal () {
    this.showPrivacyStatement = false;
    this.showTermsAndConditions = false;
    this.showReturnPolicy = false;
    this.changeBodyClass(false);
  }
}
