import {Component, OnInit} from "@angular/core";

import {fade} from "@service/animation";
import {SliderService} from "@app/business-logic/slider.service";

@Component({
  selector: "app-section-3",
  templateUrl: "./section3.component.html",
  styleUrls: ["./section3.component.scss"],
  animations: fade,
  providers: [SliderService]
})
export class Section3Component implements OnInit {
  section3List = [
    {
      img: "/assets/images/slider1.png",
      alt: "cardiomo",
      title: "advant1title",
      text: "advant1subtitle"
    },
    {
      img: "/assets/images/slider2.png",
      alt: "cardiomo",
      title: "advant2title",
      text: "advant2subtitle"
    },
    {
      img: "/assets/images/slider3.png",
      alt: "cardiomo",
      title: "advant3title",
      text: "advant3subtitle"
    }
  ];

  activeIndex = 0;
  state = "in";

  constructor(private slider: SliderService) {
    this.slider.initSlider({
      length: this.section3List.length,
      intervalAnimation: 500,
      intervalSlider: 3500
    });
  }

  ngOnInit(): void {
    this.slider._changeActiveIndex.subscribe((el: number) => {
      this.activeIndex = el;
    });
    this.slider._changeActiveStatus.subscribe((el: string) => {
      this.state = el;
    });
  }

  changeToSlider(_i: number) {
    this.slider.changeToSlider(_i);
  }
}
