import {trigger, style, animate, transition, state} from "@angular/animations";

export const fade = [
  trigger("fade", [
    state("in", style({"opacity": "1"})),
    state("out", style({"opacity": "0"})),
    transition("in <=> out", animate("500ms"))
  ])
];

export const swipe = [
  trigger("swipe", [
    state("right", style({transform: "translateX(0)"})),
    state("left", style({transform: "translateX(50px)"})),
    transition("right => left", animate("90ms")),
    transition("left => right", animate("90ms"))
  ])
];